@import '../../../styles/main.scss';

.downloadAppRootContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  background: radial-gradient(52.71% 52.71% at 50% 70.68%, $purple-color-250 0%, $purple-color-570 100%);
  box-sizing: border-box;
  border-radius: $universalSize-20;
  padding: $universalSize-24 $universalSize-24 0 $universalSize-24;
  height: $universalSize-390;
  margin-top: $universalSize-32;

  @include respondMaxWithValue(768px) {
    display: none;
  }

  .downloadAppHeading {
    font-style: normal;
    font-weight: $font-weight-section-heading;
    font-size: $font-size-small;
    line-height: $line-height-xsmall;
    text-align: center;
    color: $white-color;
  }

  .downloadAppSubHeding {
    font-style: normal;
    font-weight: $font-weight-para-heading;
    font-size: $font-size-14;
    line-height: $line-height-20;
    text-align: center;
    color: $white-color;
    margin: $universalSize-8 0 $universalSize-24 0;
  }

  .downloadAndroidIosContainer {
    display: flex;
    justify-content: center;
    cursor: pointer;
  }

  .mockMobileImageContainer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
  }
}
