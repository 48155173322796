.mainContentWithRef {
  h1 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 5px;
    line-height: 35px;
    margin-top: 15px;
    color: #282e33;

    @media screen and (min-width: 426px) {
      font-size: 28px;
    }
  }

  h2 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 5px;
    line-height: 35px;
    margin-top: 15px;
    color: #282e33;

    @media screen and (min-width: 426px) {
      font-size: 22px;
    }
  }

  h3 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
    margin-top: 10px;
    color: #282e33;

    @media screen and (min-width: 426px) {
      font-size: 20px;
    }
  }

  h4 {
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    color: #282e33;

    @media screen and (max-width: 426px) {
      font-size: 12px;
      font-weight: 19px;
    }
  }

  h5 {
    font-size: 0.75rem;
    color: #282e33;

    @media screen and (max-width: 426px) {
      font-size: 0.625rem;
    }
  }

  h6 {
    font-size: 0.625rem;
    margin-bottom: 0.313rem;
    color: #282e33;

    @media screen and (max-width: 426px) {
      font-size: 0.5rem;
    }
  }

  p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 15px;
  }

  ul {
    padding-left: 30px;
    box-sizing: border-box;
  }

  ol {
    padding-bottom: 15px;
    padding-left: 30px;
    box-sizing: border-box;
  }

  li {
    span{
      font-size: 14px;
    }
    strong{
      font-size: 16px;
    }
    color: #555a68;
    font-weight: 400;
  }

  a {
    color: #288ce4;
    text-decoration: underline;
    cursor: pointer;
    vertical-align: baseline;
  }

  .htmlBaseContentWrapper {
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    width: 100%;
    height: auto;

    @media screen and (max-width: 436px) {
      width: 90vw;
      height: auto;
    }
  }
}

.tableContent {
  p {
    word-break: break-word;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  img {
    width: 100%;
    height: auto;

    @media screen and (max-width: 436px) {
      width: 90vw;
      height: auto;
    }
  }

  .tableContainer {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    max-width: 1068px;
    margin-left: auto;
    margin-right: auto;
    scroll-margin-top: 80px;

    @media screen and (max-width: 1141px) {
      padding-left: 20px;
      padding-right: 20px;
    }

    @media screen and (max-width: 425px) {
      padding: 20px 20px;
    }
  }

  .tableSubContainer {
    display: flex;
    justify-content: center;
    overflow-x: auto;
    width: 100%;

    @media screen and (max-width: 768px) {
      justify-content: flex-start;
      overflow-x: auto;
      scroll-behavior: smooth;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  table {
    font-size: 0.625rem;
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 15px;
    margin-top: 15px;
    text-align: left;
    min-width: 600px;
    
    p {
      word-break: break-word;
      margin: 0 !important;
    }
  }

  td {
    padding: 10px;
    border: 1px solid rgb(137, 144, 161);
    font-weight: 500;
    font-size: 0.75rem;
    color: rgb(85, 90, 104);

    a {
      color: #288ce4;
      text-decoration: underline;
      vertical-align: baseline;
    }
  }

  th {
    font-weight: bold;
    font-size: 0.625rem;
    border: 1px solid #8990a1;
    padding: 10px;
    background: rgb(248, 247, 252);
    text-align: left;
  }
}

.heroImageBeforeToc {
  p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    word-break: break-word;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  img {
    width: 100%;
    height: auto;

    @media screen and (max-width: 436px) {
      width: 90vw;
      height: auto;
    }
  }
}
